import React, { useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Input, Typography, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';


export default function UpdatePassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [resetForm] = useForm();
  const navigate = useNavigate();
  const handleFormSubmit = (values) => {
    navigate('/dashboard');
  };
  return (
    <MainLayout>
      <h1 className='font-slightly-bold font-poppins text-2xl text-[#455A64] lg:p-10 p-5'>Update Password</h1>
      <div className='rounded-lg bg-white p-5 font-poppins mx-5 lg:mx-10'>
        <h1 className='font-slightly-bold text-2xl text-[#202224]'>Password</h1>
        <Form layout='vertical' className='mt-0 ' onFinish={handleFormSubmit} form={resetForm}>
        <div className='flex flex-col md:flex-row justify-between md:gap-4  xl:gap-9'>
          <div className="md:w-1/3">
            <Typography.Paragraph className='font-poppins  font-slightly-bold text-[#202224]  text-sm my-3'>Current Password</Typography.Paragraph>
            <Form.Item name="current" rules={[{ required: true, message: 'Please enter current password' }]}>
              <Input.Password type='password' className='w-full px-4 py-3 border rounded-md' placeholder='*******'
                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
              />
            </Form.Item>
          </div>
          <div className="md:w-1/3">
            <Typography.Paragraph className='font-poppins  font-slightly-bold text-[#202224]  text-sm  my-3'>New Password</Typography.Paragraph>
            <Form.Item name="new" rules={[{ required: true, message: 'Please enter new password' }]}>
              <Input.Password type='password' className='w-full px-4 py-3 border rounded-md'
              visibilityToggle={{ visible: newPasswordVisible, onVisibleChange: setNewPasswordVisible }}
              placeholder='Enter New Password' />
            </Form.Item>
          </div>
          <div className="md:w-1/3">
            <Typography.Paragraph className='font-poppins  font-slightly-bold text-[#202224]  text-sm my-3'>Confirm New Password</Typography.Paragraph>
            <Form.Item name="confirm_password" rules={[{ required: true, message: 'Confirm password cannot be empty' }]}>
              <Input.Password type='password' name='password' className='w-full px-4 py-3 border rounded-md'
              visibilityToggle={{ visible: confirmPasswordVisible, onVisibleChange: setConfirmPasswordVisible }}
              placeholder='Confirm New Password' />
            </Form.Item>
          </div>

        </div>
        <Link to="/verify" className="text-primary font-poppins font-slightly-bold">Forgot password?</Link>
        <div className='flex gap-4 mt-10'>
          <div>
          <button className='px-12 rounded-md p-2 border border-primary text-primary font-poppins font-slightly-bold'>Cancel</button>
          </div>

          <Form.Item>
          <button type='submit' className='px-12 border border-primary rounded-md p-2 bg-primary text-white font-poppins font-slightly-bold'>
            Update
          </button>
        </Form.Item>
        </div>

        </Form>
      </div>
    </MainLayout>
  )
}
