import React from "react";
import { Modal } from "antd";

export default function ViewMoreModal({ open, onClose, notes }) {
  return (
    <Modal
      title="Detailed Notes"
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <p>{notes}</p>
    </Modal>
  );
}
