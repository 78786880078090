import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Input, Typography, Button, Form, message as Notify} from "antd";
import Api from "../../utils/Axios";
import AuthLayout from "../../layouts/AuthLayout";
import Guest from "../../components/Middleware/Guest";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [resetForm] = Form.useForm();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      const {message} = await Api.Post('/forget-password', values)
      Notify.success(message);
      navigate(`/verify?email=${values.email}`);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorField = error.response.data.errors;
        if (errorField['code']) {
          Notify.error('Please wait. we have already sent an OTP.');
          navigate(`/verify?email=${values.email}`);
          return
        }
        const validationErrors = Object.keys(errorField).map((key) => ({
          name: key,
          errors: errorField[key],
        }));
        resetForm.setFields(validationErrors);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false)
    }
  }, [navigate, resetForm])

  return (
    <Guest>
      <AuthLayout>
        <div className='mt-10 mb-5'>
          <Typography.Title
            level={2}
            className='text-left text-dark font-semibold font-poppins'>
            Forget <span className='text-secondary'>Password</span>
          </Typography.Title>
          <Typography.Paragraph className='font-poppins text-left font-light'>
            Forgot Your Password? No Problem! Just enter your email below, and
            we'll send you a link to reset it in no time.
          </Typography.Paragraph>
        </div>
        <Form
          layout='vertical'
          className='mt-14'
          onFinish={handleFormSubmit}
          form={resetForm}
          disabled={loading}
        >
          <div className=''>
            <Typography.Paragraph className='font-poppins text-dark text-sm mb-1.5'>
              Email*
            </Typography.Paragraph>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: "Please input your work email",
                },
                {
                  validator: (_, value) => {
                    if (value) {

                      if (/\s/.test(value)) {
                        return Promise.reject(
                          "Email address should not contain spaces."
                        );
                      }
                      
                      const emailRegex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-])*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
                      if (!emailRegex.test(value)) {
                        return Promise.reject(
                          "Please enter a valid email address."
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}>
              <Input
                className='w-full px-4 py-3 border rounded-md'
                placeholder='Enter your work email'
              />
            </Form.Item>
          </div>
          <Form.Item className='mt-8'>
            <Button
              type='primary'
              htmlType='submit'
              className='w-full h-12 bg-primary text-white font-poppins text-medium'
              loading={loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </AuthLayout>
    </Guest>
  );
}
