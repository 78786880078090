import { Button, Form, Input, Modal, message as Notify, Select, Typography } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";
import DepartmentIcon from "./DepartmentIcon";


export default function DepartmentModal({ open, onClose, department }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#0D0B9F");
  const [previousHead, setPreviousHead] = useState(undefined)
  const [selectedHead, setSelectedHead] = useState(undefined)
  const [departmentForm] = useForm();
  const departmentHead = useWatch('department_head_id', departmentForm);
  const colors = ["#0D0B9F", "#73C255", "#ED0000", "#3376FF"];

  const handleModalClose = useCallback((refresh) => {
    departmentForm.resetFields();
    onClose(refresh)
  }, [onClose, departmentForm])

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true);

      let call;

      if (department) {
        call = () => Api.Put(`/organization/${activeOrganization?.id}/department/${department?.id}`, values);
      } else {
        call = () => Api.Post(`/organization/${activeOrganization?.id}/department`, values);
      }

      const { message } = await call();
      departmentForm.resetFields();
      Notify.success(message);
      handleModalClose(true)

    } catch (error) {
      if (error.response && [400, 422].includes(error.response.status)) {
        Notify.error(error.response.data.message);
      } else {
        Notify.error('Something went wrong');
      }
      console.error(error)
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, departmentForm, department, handleModalClose]);

  const getOrganizationUsers = useCallback(async () => {
    try {
      const { data } = await Api.Get(`/organization/${activeOrganization?.id}/users`);
      setUsers(() => {
        return data.map(item => ({ value: item.id, label: item.name }))
      });
    } catch (error) {

    }
  }, [activeOrganization]);

  const handleUserRemove = useCallback((value) => {
    if (value === selectedHead) {
      setPreviousHead(undefined);
      setSelectedHead(undefined);
      departmentForm.setFieldValue('department_head_id', undefined);
    }
  }, [departmentForm, selectedHead])

  useEffect(() => {
    if (departmentHead && departmentForm) {
      const oldMembers = departmentForm.getFieldValue('members');
      const membersWithNewHead = oldMembers?.filter(memberId => memberId !== previousHead);
      departmentForm.setFieldValue('members', [...new Set([...(membersWithNewHead ?? []), departmentHead])])
    }
  }, [departmentHead, departmentForm, previousHead]);

  useEffect(() => {
    getOrganizationUsers()
  }, [getOrganizationUsers])

  useEffect(() => {
    if (department) {
      const ref = {
        name: department.name ?? '',
        department_head_id: department.department_head?.id ?? undefined,
        color: department.color,
        members: department.members?.map(member => member.id) ?? []
      }
      setSelectedColor(department.color)
      departmentForm.setFieldsValue(ref);
    }
  }, [departmentForm, department])

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => handleModalClose()}
        footer={false}
        destroyOnClose
      >
        <div className='font-poppins'>
          <h1 className='font-slightly-bold text-lg'>{!department ? "Create Department" : "Update Department"}</h1>
          <Form
            layout='vertical'
            className='mt-4'
            onFinish={handleFormSubmit}
            form={departmentForm}
            disabled={loading}
          >
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Department Name <span className="text-red-500">*</span>
              </Typography.Paragraph>
              <Form.Item
                name='name'
                rules={[
                  { required: true, message: "Please enter a Department Name" },
                  { max: 50, message: "The Department Name field must not be greater than 50 Characters" }
                ]}>
                <Input
                  className='w-full px-2 py-2 border rounded-md'
                  placeholder='Department Name'
                />
              </Form.Item>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Department Head <span className="text-red-500">*</span>
              </Typography.Paragraph>
              <Form.Item
                name='department_head_id'
                className='department'
                rules={[
                  { required: true, message: "Please select a Department Head" },
                ]}>
                <Select
                  showSearch
                  className='h-10'
                  placeholder='Select Head'
                  options={users}
                  onChange={(value) => setSelectedHead((prev) => {
                    setPreviousHead(prev)
                    setSelectedHead(value)
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Members <span className="text-red-500">*</span>
              </Typography.Paragraph>
              <Form.Item
                name='members'
                className='department'
                rules={[{ required: true, message: "Please select Members" }]}>
                <Select
                  mode='multiple'
                  placeholder='Select members'
                  options={users}
                  onDeselect={(handleUserRemove)}
                  maxTagCount={3}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Profile
              </Typography.Paragraph>
              <Form.Item
                name='color'
                initialValue={colors[0]}
                rules={[{ required: true, message: "Please select Profile" }]}>
                <div className='flex gap-2'>
                  {colors.map((color, index) => (
                    <DepartmentIcon
                      key={`color-${index}`}
                      selected={selectedColor === color}
                      value={color}
                      onChange={(color) => {
                        setSelectedColor(color)
                        departmentForm.setFieldValue('color', color)
                      }}
                      size='medium'
                    />
                  ))}
                </div>
              </Form.Item>
            </div>
            <div className='flex gap-5'>
              <Button
                type='primary'
                htmlType='submit'
                className='w-1/2 bg-primary h-10 mt-5 rounded-md'
                loading={loading}
              >
                {!department ? "Create" : "Update"}
              </Button>
              <Button
                className=' w-1/2 h-10 mt-5 rounded-md'
                onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
