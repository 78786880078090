import { Button, Modal, Upload } from 'antd';
import { useCallback, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { validateFile } from '../utils/fileValidation'

export default function Uploader({ src, onSuccess }) {

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleFileChange = useCallback(
    ({ file }) => {
      if (file.status === "done") {
        const { response } = file;
        onSuccess(response);
        setShowPreviewModal(false);
      }
    },
    [onSuccess]
  );

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="relative">
        <div className="border-[6px] border-primary rounded-full bg-white">
          <img
            alt="profile"
            src={src}
            className="rounded-full w-[120px] h-[120px] object-contain object-top"
          />
        </div>
        <div className="absolute right-4 bottom-0">
          <Button
            className="bg-white rounded-full p-2"
            onClick={() => setShowPreviewModal(true)}
          >
            <FiEdit />
          </Button  >
        </div>
      </div>
      <Modal
        open={showPreviewModal}
        onCancel={() => setShowPreviewModal(false)}
        footer={false}
        centered
        destroyOnClose
      >
        <h1 className="text-lg font-poppins font-semibold"> Preview</h1>
        <div className="relative w-fit mx-auto">
          <div className="">
            <img
              alt="profile"
              src={src}
              width={250}
              height={250}
              className="rounded-full mx-auto border-2 border-primary w-[250px] h-[250px] object-contain"
            />
          </div>
          <div className="absolute right-4 bottom-0">
            <Upload
              name="file"
              accept="image/*"
              showUploadList={false}
              action={
                process.env.REACT_APP_BACKEND_URL + "/upload"
              }
              headers={{
                Authorization: `Bearer ${localStorage.getItem(
                  "token"
                )}`,
              }}
              beforeUpload={validateFile}
              onChange={handleFileChange}
            >
              <Button className="bg-white rounded-full p-2 h-12 w-12 flex justify-center items-center">
                <FiEdit size={25} />
              </Button>
            </Upload>

          </div>
        </div>
      </Modal>
    </div>
  )
}
