import {useCallback, useEffect, useState} from "react";
import MainLayout from "../../layouts/MainLayout";
import {Avatar, Button, Space, Table, Tag, Tooltip, message as Notify, Empty} from "antd";
import {useSelector} from "react-redux";
import Api from "../../utils/Axios";
import Search from "antd/es/input/Search";
import ProjectModal from "./components/ProjectModal";
import {useNavigate} from "react-router-dom";
import ProjectStatusModal from "../../components/Modals/ProjectStatusModal";
import Authorized from "../../components/Middleware/Authorized";

export default function Projects() {

  const {activeOrganization} = useSelector((state) => state.auth);
  const [tableLoading, setTableLoading] = useState(false);
  const [showProjectStatusModal, setShowProjectStatusModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [archived, setArchived] = useState(false);
  const navigate = useNavigate();

  const getProjects = useCallback(async () => {
    try {
      setTableLoading(true);
      const {data} = await Api.Get(`organization/${activeOrganization?.id}/project`, {
        search,
        archived
      });
      setProjects(data);
    } catch (error) {
      console.error(error);
      Notify.error('Something went wrong');
    } finally {
      setTableLoading(false)
    }
  }, [activeOrganization, search, archived])

  const handleProjectModalClose = useCallback((refresh) => {
    setShowProjectModal(false);
    setSelectedProject(undefined)
    if (refresh) {
      getProjects()
    }
  }, [getProjects])

  const handleProjectStatusModalClose = useCallback((refresh) => {
    setShowProjectStatusModal(false);
    setSelectedProject(undefined)
    if (refresh) {
      getProjects()
    }
  }, [getProjects])


  useEffect(() => {
    getProjects()
  }, [getProjects])

  const columns = [
    {
      title: 'S.no',
      dataIndex: 'id',
      key: 'id',
      render: (_, $, index) => index + 1
    },
    {
      title: 'Project',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Assigned Departments',
      dataIndex: 'departments',
      key: 'departments',
      render: (departments) => (
        <div className='flex items-center justify-center'>
          {departments.length > 0 ? (
            <div className='avatar'>
              <Avatar.Group
                maxCount={2}
                maxPopoverTrigger='hover'
                size='default'
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                }}>
                {departments.map((item, index) => (
                  <Tooltip
                    title={item.name}
                    placement='top'>
                    <Avatar
                      key={`user-${item.id}-${index}`}
                      src={`https://ui-avatars.com/api/?name=${item?.name}`}
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          ) : (
            <p className='ml-6 text-center'>---</p>
          )}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return <Tag className="capitalize" color={text === 'active' ? 'green' : 'red'}>{text}</Tag>
      }
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (

          activeOrganization.role === 'admin' && (
            <Space>
              {
                !record.archived ? (
                  <Button className="bg-primary text-white border-primary" onClick={() => {
                    setSelectedProject(record);
                    setShowProjectModal(true);
                  }}>
                    Edit
                  </Button>
                ) : null
              }
              {
                !record.archived ? (
                  <Button className="bg-red-500 text-white border-red-500" onClick={() => {
                    setSelectedProject(record);
                    setShowProjectStatusModal(true);
                  }}>
                    Archive
                  </Button>
                ) : (
                  <Button className="bg-green-600 text-white border-green-600" onClick={() => {
                    setSelectedProject(record);
                    setShowProjectStatusModal(true);
                  }}>
                    Restore
                  </Button>
                )
              }
            </Space>
          )
        )
      }
    }
  ]

  return (
    <MainLayout>
      <Authorized permission={true}>
        <div className='py-8 md:p-5 font-poppins'>
          <div className="flex items-center gap-x-6 mt-4 mb-7">
            <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
              </svg>
            </Button>
            <h1 className="text-2xl font-semibold flex items-center">
              Projects
              <Tag
                className="ml-2 shadow-lg"
                color={archived ? 'red' : 'green'}
              >
                {archived ? "Archived" : "Active"}
              </Tag>
            </h1>
          </div>
          <div className='lg:flex justify-between items-center '>
            <div className='dashboard w-full bg-white p-2 rounded-md relative '>
              <div className="text-right">
                <Space className="my-2.5">
                  <Search
                    disabled={tableLoading}
                    placeholder='Search'
                    allowClear
                    onSearch={(value) => {
                      setSearch(value)
                    }}
                    size="large"
                  />
                  <Button
                    disabled={tableLoading}
                    onClick={() => setArchived((prevArchive) => !prevArchive)}
                    className='w-full font-poppins rounded-md h-10'>
                    {archived ? "Archived Projects" : "Active Projects"}
                  </Button>
                  {
                    !archived && activeOrganization.role === 'admin' && (
                      <Button
                        disabled={tableLoading}
                        type="primary" className="h-10" onClick={() => setShowProjectModal(true)}>
                        + Add Project
                      </Button>
                    )
                  }
                </Space>
              </div>
              <Table
                columns={columns}
                dataSource={projects}
                loading={tableLoading}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Project Found"
                    />
                  ),
                }}
              />
              <ProjectModal
                open={showProjectModal}
                onClose={handleProjectModalClose}
                project={selectedProject}
              />
              <ProjectStatusModal
                open={showProjectStatusModal}
                onClose={handleProjectStatusModalClose}
                project={selectedProject}
              />
            </div>
          </div>
        </div>
      </Authorized>
    </MainLayout>
  );
}
