import React, {createContext, useContext, useState} from 'react';

const PageUtilsContext = createContext({});

export const PageUtilsProvider = ({children, states = {}}) => {

  return (
      <PageUtilsContext.Provider value={{
        ...states
      }}>
        {children}
      </PageUtilsContext.Provider>
  );
};

export const usePageUtils = () => useContext(PageUtilsContext);
