import React from "react";
import {Layout} from "antd";
import Navbar from "../components/Layout/Navbar";
import SidebarContent from "../components/Layout/SidebarContent";
import Auth from "../components/Middleware/Auth";
import Subscriber from "../components/Middleware/Subscriber";

const {Header, Content} = Layout;

const MainLayout = ({children}) => {

  return (
      <Auth>
        <Subscriber>
          <Layout>
            <Header
                style={{
                  display: "flex",
                  justifyContent: "end",
                  backgroundColor: "white",
                  padding: "0px",
                }}
                className="font-poppins"
            >
              <Navbar/>
            </Header>
            <Layout className="font-poppins">
              <SidebarContent/>
              <Layout>
                <Content>{children}</Content>
              </Layout>
            </Layout>
          </Layout>
        </Subscriber>
      </Auth>
  );
};
export default MainLayout;
