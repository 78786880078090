import React, { useCallback, useEffect, useState } from "react";
import Status from "./Status";
import RecentActivity from "./RecentActivity";
import UpcomingMeetings from "./UpcomingMeetings";
import Summary from "./Summary";
import TimeSummary from "./TimeSummary";
import ActivityLog from "./ActivityLog";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";
import RecentActivitiesLoader from "../Skeletons/RecentActivities";
import UpcomingMeetingLoader from "../Skeletons/UpcomingMeetingLoader";
import LazyLoad from "../LazyLoad";
import CustomDatePicker from '../CustomDatePicker';
import dayjs from 'dayjs';

export default function MyReport() {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [activities, setActivities] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const [date, setCurrentDate] = useState(dayjs());
  const [meetingLoading, setMeetingLoading] = useState(false);

  const getRecentActivities = useCallback(async () => {
    try {
      setScreenshotLoading(true);
      const { data } = await Api.Post(
        `/organization/${activeOrganization?.id}/my-report/recent-activities`,
        {
          date: date.toISOString()
        }
      );
      setActivities(data);
    } catch (error) {
      console.error(error);
    } finally {
      setScreenshotLoading(false)
    }
  }, [activeOrganization, date]);

  const getUpcomingMeeting = useCallback(async () => {
    try {
      setMeetingLoading(true);
      const { data } = await Api.Post(
        `/organization/${activeOrganization?.id}/my-report/meetings`,
        {
          date: date.toISOString()
        }
      );
      setMeetings(data);
    } catch (error) {
      console.error(error)
    } finally {
      setMeetingLoading(false)
    }
  }, [activeOrganization, date]);

  useEffect(() => {
    getRecentActivities();
    getUpcomingMeeting();
  }, [getRecentActivities, getUpcomingMeeting]);

  return (
    <div className="font-poppins">
      <div className='flex mb-4 justify-end'>
        <CustomDatePicker onChange={(value) => setCurrentDate(value)} />
      </div>
      <Status date={date} />
      <div className="grid grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 lg:col-span-2">

          <LazyLoad loading={screenshotLoading} loader={<RecentActivitiesLoader cards={3} />}>
            <RecentActivity activities={activities} />
          </LazyLoad>

          <LazyLoad loading={meetingLoading} loader={<UpcomingMeetingLoader />}>
            <UpcomingMeetings meetings={meetings} />
          </LazyLoad>
          <Summary date={date} />
        </div>
        <div className="col-span-2 lg:col-span-1 p-2">
          <TimeSummary date={date} />
          <ActivityLog date={date} />
        </div>
      </div>
      {/*<Summary date={date} />*/}
    </div>
  );
}
