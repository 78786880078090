import { useCallback, useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import Cover from "../../assets/cover.jpg";
import { Button, Modal, Select, Tag, Upload, message as Notify } from "antd";
import { Input, Typography, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import Edit from "../../assets/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import Api from "../../utils/Axios";
import { updateUser } from "../../features/auth/authSlice";
import { validateFile } from "../../utils/fileValidation";
import {useNavigate} from "react-router-dom";

export default function Profile() {
  const dispatch = useDispatch();
  const [profileForm] = useForm();
  const coverImageInput = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [profileImage, setProfileImage] = useState(user.avatar);
  const [loading, setLoading] = useState(false);
  const [coverImage, setCoverImage] = useState();
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleFileChange = useCallback(
    ({ file }) => {
      if (file.status === "done") {
        const { response } = file;
        setProfileImage(response.data.avatar);
        dispatch(updateUser(response.data));
      }
    },
    [dispatch]
  );

  const handleCoverImageUploadOpen = useCallback(() => {
    coverImageInput.current.click();
  }, [coverImageInput]);

  const handleCoverImageChange = useCallback(
    async (event) => {
      try {
        const file = event.target.files[0];
        if (!file) return;

        // Validate file
        if (!validateFile(file)) return;

        const formData = new FormData();
        formData.append("file", file);
        const response = await Api.Post("upload", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        const newCover =
          process.env.REACT_APP_BACKEND_URL.replace("api/v1", "storage/") +
          response;
        setCoverImage(newCover);
        profileForm.setFieldValue("cover_image", response);
      } catch (error) {
        console.error("Error uploading cover image:", error);
      }
    },
    [profileForm]
  );

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const { message, data } = await Api.Post("profile/update", values);
        dispatch(updateUser(data));
        Notify.success(message);
        navigate('/dasboard');
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const errors = error.response.data.errors;
          const validationErrors = Object.keys(errors).map((key) => ({
            name: key,
            errors: errors[key],
          }));
          profileForm.setFields(validationErrors);
        } else {
          console.error(error);
          Notify.error("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
    [profileForm, dispatch]
  );

  useEffect(() => {
    setCoverImage(user?.cover_image ?? Cover);
  }, [user]);

  return (
    <MainLayout>
      <h1 className="font-slightly-bold font-poppins text-2xl text-[#455A64] px-10 py-5">
        Profile
      </h1>
      <div className="rounded-md md:mx-10  bg-white">
        <div className="font-poppins mx-auto relative">
          <div className="">
            <img
              src={coverImage}
              alt="user_cover_image"
              className="w-full h-[220px] object-cover"
            />
          </div>
          <div className="absolute right-10 bottom-[-20px] z-20">
            <Button
              className="bg-white rounded-full p-2 flex items-center justify-center h-10 w-10 "
              onClick={handleCoverImageUploadOpen}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Button>
          </div>
          <input
            type="file"
            className="hidden"
            ref={coverImageInput}
            onChange={handleCoverImageChange}
            accept="image/*"
          />
          <div className="bg-white">
            <div className="flex flex-col items-center justify-center absolute left-0 right-0 top-10 md:top-24 lg:top-32 xl:-bottom-48">
              <div className="relative">
                <div className="border-[8px] border-primary rounded-full bg-white">
                  <img
                    alt="profile"
                    src={profileImage}
                    className="rounded-full w-[150px] h-[150px] object-contain object-top"
                  />
                </div>
                <div className="absolute right-4 bottom-0">
                  <button
                    className="bg-white rounded-full p-2"
                    onClick={showModal}
                  >
                    <img src={Edit} alt="edit-icon" />
                  </button>
                </div>
              </div>
              <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
                centered
                destroyOnClose
              >
                <h1 className="text-lg font-poppins font-semibold"> Preview</h1>
                <div className="relative w-fit mx-auto">
                  <div className="">
                    <img
                      alt="profile"
                      src={profileImage}
                      width={250}
                      height={250}
                      className="rounded-full mx-auto border-2 border-primary w-[250px] h-[250px] object-contain"
                    />
                  </div>
                  <div className="absolute right-4 bottom-0">
                    <Upload
                      name="image"
                      showUploadList={false}
                      accept="image/*"
                      action={
                        process.env.REACT_APP_BACKEND_URL + "/profile/avatar"
                      }
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      }}
                      beforeUpload={validateFile}
                      onChange={handleFileChange}
                    >
                      <Button className="bg-white rounded-full p-2 h-10 w-10">
                        <img src={Edit} alt="edit-icon" />
                      </Button>
                    </Upload>
                  </div>
                </div>
                {/* <div className='flex justify-end mt-5 '>
                  <button
                    className='bg-primary text-white p-2 rounded-lg w-36 font-poppins m-2'
                    onClick={handleCancel}
                    type='button'>
                    Confirm
                  </button>
                </div> */}
              </Modal>
              <div className="text-center mt-4">
                <p className="font-semibold text-[#263238] text-xl capitalize">
                  {user.username}
                </p>
                <p className="text-[#818181] text-base">
                  {user?.departments?.map((tag) => (
                    <Tag color="processing" key={tag}>
                      {tag}
                    </Tag>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white px-10 py-4 rounded-e-lg mt-40">
          <Form
            layout="vertical"
            className="mt-0 "
            onFinish={handleFormSubmit}
            initialValues={{
              name: user.name,
              departments: user.departments,
              email: user?.email,
            }}
            form={profileForm}
            disabled={loading}
          >
            <div className="flex flex-col md:flex-row justify-between md:gap-4  xl:gap-9">
              <div className="md:w-1/3">
                <Form.Item name="cover_image" className="hidden">
                  <Input />
                </Form.Item>
                <Typography.Paragraph className="font-poppins  font-slightly-bold text-[#202224]  text-sm  my-3">
                  Full Name
                </Typography.Paragraph>
                <Form.Item name="name">
                  <Input
                    type="text"
                    className="w-full px-4 py-3 border rounded-md"
                    name="name"
                  />
                </Form.Item>
              </div>
              <div className="md:w-1/3">
                <Typography.Paragraph className="font-poppins  font-slightly-bold text-[#202224]  text-sm  my-3">
                  Email
                </Typography.Paragraph>
                <Form.Item name="email">
                  <Input
                    type="email"
                    className="w-full px-4 py-3 border rounded-md"
                    disabled
                    placeholder={user.email}
                  />
                </Form.Item>
              </div>
              <div className="md:w-1/3">
                <Typography.Paragraph className="font-poppins  font-slightly-bold text-[#202224]  text-sm my-3">
                  Department
                </Typography.Paragraph>
                <Form.Item name="departments">
                  <Select mode="multiple" className="depart" disabled />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="flex justify-center my-4">
              <Button
                htmlType="submit"
                className="px-24 mx-auto border border-primary rounded-md p-2 bg-primary text-white font-poppins font-slightly-bold h-10"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </MainLayout>
  );
}
