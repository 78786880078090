import React, { useCallback, useEffect, useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import { Button, Table as AntTable, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import Filters from '../components/Filters';
import { useSelector } from 'react-redux';
import Api from '../../../utils/Axios';
import TimeSheetDrawer from '../../../components/Timesheet/TimeSheetDrawer';
import clsx from 'clsx';
import Authorized from '../../../components/Middleware/Authorized';

const ENABLED_FILTERS = {
  departments: true,
  projects: true,
  user: true,
  single_picker: true,
}

export default function DailyTimesheetReport() {

  const navigate = useNavigate();
  const [filters, setFilters] = useState(undefined)

  return (
    <MainLayout>
      <Authorized permission='daily-timesheet'>
        <div className='md:p-5 py-2'>
          <div className='flex items-center gap-x-6'>
            <div className='flex items-center gap-4 mt-4 mb-7'>
              <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
              </Button>
              <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
                Daily Timesheet
              </h1>
            </div>
            <div className='flex justify-end items-center flex-1 gap-2'>
              <div className="flex gap-2 xl:items-center main flex-col flex-1">
                <div className="flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full">
                  <Filters
                    filterss={ENABLED_FILTERS}
                    onChange={(newFilters) => setFilters(newFilters)}
                    report='daily-timesheet'
                  />
                </div>
              </div>
            </div>
          </div>
          <Table filters={filters} />
        </div>
      </Authorized>
    </MainLayout>
  )
}


const Table = ({ filters }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalHours, setTotalHours] = useState('');
  const [pagination] = useState(undefined);
  const [recordFilters, setRecordFilters] = useState(undefined);
  const [showWorklogDrawer, setShowWorklogDrawer] = useState(false);
  const [worklogLoading, setWorklogLoading] = useState(false);
  const [worklog, setWorklog] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth);

  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      const { data: { data: records, total_hours } } = await Api.Get(`organization/${activeOrganization.id}/report/daily-timesheet`, recordFilters)
      setData(records);
      setTotalHours(total_hours)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }, [activeOrganization, recordFilters])

  const handleLoadMore = useCallback(async () => {
    const { data: { data: records } } = await Api.Get(pagination?.links?.next, recordFilters)
    setData((prev) => [
      ...prev,
      ...records
    ]);
    // setPagination(paginationData)
  }, [pagination, recordFilters])

  const handleGetWorklog = useCallback(async (record) => {
    try {
      setShowWorklogDrawer(true);
      setWorklogLoading(true);

      const { data } = await Api.Post(`organization/${activeOrganization.id}/worklog`, {
        date: record?.date,
        project_id: record?.id,
        users: filters?.team_members,
        departments: filters?.departments
      })

      const timeToSeconds = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return (hours * 3600) + (minutes * 60);
      }

      setWorklog({
        ...data,
        total_hours: timeToSeconds(record.logged_time),
        user_ids: filters?.team_members ?? [],
        department_ids: filters?.departments ?? [],
      });
    } catch (error) {
      console.error(error)
    } finally {
      setWorklogLoading(false)
    }
  }, [filters, activeOrganization])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

  useEffect(() => {
    getReport()
  }, [getReport])

  const columns = [
    {
      title: 'Project name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Total (Hrs)',
      dataIndex: 'logged_time',
      key: 'logged_time',
      align: 'right',
      render: (data, record) => (
        <p className={clsx({
          'underline cursor-pointer': record.logged_time !== '00:00'
        })} onClick={() => {
          if (record.logged_time !== '00:00') {
            handleGetWorklog(record)
          }
          return false
        }}>{data}</p>
      )
    }
  ];

  return (
    <div className='bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        className='m-2'
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Daily Timesheet Found"
            />
          ),
        }}
      />

      <div className='sticky bottom-3 mt-4 flex px-7 bg-[#F1F4F9] justify-between p-5 rounded-md font-poppins text-[#263238] font-semibold'>
        <p className=''>Total</p>
        <p>{totalHours}</p>
      </div>

      <TimeSheetDrawer
        onClose={() => {
          setShowWorklogDrawer(false)
          setWorklog(undefined)
        }}
        open={showWorklogDrawer}
        loading={worklogLoading}
        worklog={worklog}
      />

      {
        pagination?.links?.next && (
          <div className="load-more sticky flex justify-center top-0">
            <Button onClick={handleLoadMore} type="primary" className="font-poppins py-2 px-5 h-auto">Load More</Button>
          </div>
        )
      }
    </div>
  );
}
