import { Button, Form, Input, Modal, message as Notify } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";

export default function AddNotesModal({ open, meeting, onClose }) {

  const { activeOrganization, user } = useSelector((state) => state.auth);
  const [notesForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const viewable = useMemo(() => user.id === meeting?.host_id || activeOrganization.role === 'admin', [user, activeOrganization, meeting?.host_id])

  const handleFormSubmission = useCallback(async (values) => {
    try {
      setLoading(true);
      const { message } = await Api.Post(`/organization/${activeOrganization?.id}/meeting/${meeting?.id}/notes`, values);
      notesForm.resetFields();
      Notify.success(message);
      onClose(true)
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const validationErrors = Object.keys(errors).map((key) => ({
          name: key,
          errors: meeting.notes ? ['Please enter a note before updating'] : errors[key],
        }));
        notesForm.setFields(validationErrors);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, notesForm, meeting, onClose]);

  useEffect(() => {
    notesForm.setFieldValue('notes', meeting?.notes ?? '')
  }, [meeting, notesForm]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      className='mt-0'
      footer={false}
      destroyOnClose
    >
      <Form
        layout='vertical'
        onFinish={handleFormSubmission}
        form={notesForm}
      >
        <h1 className='font-poppins text-lg font-bold my-2'>
          {viewable ? (meeting?.notes ? "Update Notes" : "Add Notes") : "View Notes"}
        </h1>
        <Form.Item name='notes'>
          <Input.TextArea
            disabled={!viewable}
            placeholder='Add notes for the meeting'
            rows={8}
            className="disabled:bg-transparent disabled:text-black"
          />
        </Form.Item>
        <Form.Item className='text-right'>
          {
            viewable && (
              <Button
                type='primary'
                htmlType='submit'
              >
                {meeting?.notes === null ? "Add Notes" : "Update Notes"}
              </Button>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}
