import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ChakraProvider} from '@chakra-ui/react'
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import {HistoryRouter} from './components/HistoryRouter';
import {injectStore} from './utils/helpers';

const root = ReactDOM.createRoot(document.getElementById('root'));
injectStore(store);

root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider>
          <HistoryRouter/>
          <App/>
        </ChakraProvider>
      </Provider>
    </BrowserRouter>
);
